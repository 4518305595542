export const BASE_URI = (
  process.env.NODE_ENV == 'development' ?
    'http://localhost:8000' :
    'https://api.openverkiezingen.nl'
);

export const SUMMARIZER_URI = (
  process.env.NODE_ENV == 'development' ?
    'http://localhost:8001' :
    'https://summarizer.openverkiezingen.nl'
);

export const AMPLITUDE_KEY = (
  process.env.NODE_ENV == 'development' ?
    '' :
    '84211f8b7828b8d14367822e123650f9'
);

export const GROWTH_BOOK_CLIENT_KEY = 'sdk-Ntz5rpwlGUnhUX7o';

export const DEV_MODE = process.env.NODE_ENV == 'development';

export const MIN_STATEMENT_LENGTH = 5;

export const DEFAULT_ELECTION = 'EP2024';

export const deprecationWarning =
  process.env.NODE_ENV !== 'production' ? console.warn : () => {};

export const DONATION_LINK = 'https://bunq.me/StichtingOpenPolitiek';
